.listWrapper {
  display: flex;
  align-content: center; }

.errorBack {
  font-size: 3rem;
  margin-top: 5rem;
  cursor: pointer; }

.expandControlsContainer {
  display: flex;
  justify-content: space-between;
  padding: 2rem 2rem 1rem; }

.documentOverview {
  max-width: 900px;
  margin: 0 auto; }
