.layout {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 100%;
    min-width: 100%;
}

.header{
    display: flex;
    flex-direction: row;
    min-height: 100px;
/*    use clamp for practice and mobile?*/
}

.footer{
    display: flex;
    flex-direction: row;
    min-height: 100px;
}

.sidePadding{
    min-width: 10%;
}

.mainContent{
    display: flex;
    flex-direction: row;
    min-width: 100%;
    min-height: calc(100vh - 200px);
}

.contentContainer{
    text-align: center;
    min-width: 80%;
    min-height: 100%;
}
