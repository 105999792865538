.greenCheckmark {
  color: green; }
.orangeCheckmark {
  color: orange; }

.iconSize {
  font-size: 2.5rem; }

.modalContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 2rem;
    width: fit-content; }

.rowFlex {
  display: flex;
  flex-direction: row;
  justify-content: center; }
